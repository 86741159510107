import {
  useFormSection,
  useFormFieldGroup,
  useTextField
} from "@/components/FormBuilder/Helpers";
import { FormBlock } from "@/components/FormBuilder/types";

export const claimsSchemas = (formValues: any): FormBlock[] => [
  ...useFormSection(
    [
      ...useFormFieldGroup(
        [
          useTextField({
            key: "Name",
            label: "Name",
            readonly: true
          }),
          useTextField({
            key: "ClaimNumber",
            label: "Claim Number",
            readonly: true
          }),
          useTextField({
            key: "PolicyNum",
            label: "Policy Number",
            readonly: true
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useTextField({
            key: "Address",
            label: "Address",
            readonly: true
          }),
          useTextField({
            key: "City",
            label: "City",
            readonly: true
          }),
          useTextField({
            key: "State",
            label: "State",
            readonly: true
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useTextField({
            key: "Zip",
            label: "Zip Code",
            readonly: true
          })
        ],
        { layout: "3-col" }
      )
    ],
    {
      title: `${formValues["CompanyName"].toUpperCase()} POLICY INFORMATION`
    }
  ),
  ...useFormSection(
    [
      ...useFormFieldGroup(
        [
          useTextField({
            key: "ClaimNumber",
            label: "Claim Number",
            readonly: true
          }),
          useTextField({
            key: "LossDt",
            label: "Date of Loss",
            readonly: true
          }),
          useTextField({
            key: "StaffAdj",
            label: "Staff Adjuster",
            readonly: true
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useTextField({
            key: "FieldAdj",
            label: "Field Adjuster",
            readonly: true
          }),
          useTextField({
            key: "LossPToDt",
            label: "Loss Paid To Date",
            readonly: true
          }),
          useTextField({
            key: "Extension",
            label: "Extension",
            readonly: true
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useTextField({
            key: "CauseOfLoss",
            label: "Cause of Loss", //TODO: the value here was just the key - get the value
            readonly: true
          }),
          useTextField({
            key: "ClaimsStatus",
            label: "Claim Status",
            readonly: true
          }),
          useTextField({
            key: "PhoneNum",
            label: "Phone Number",
            readonly: true
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup([], { layout: "3-col" })
    ],
    { title: "CLAIM INFORMATION" }
  )
];

export const coverageBreakDownSchemas = [
  ...useFormSection(
    [
      ...useFormFieldGroup(
        [
          useTextField({
            key: "DWG",
            label: "Dwelling",
            readonly: true
          }),
          useTextField({
            key: "ALE",
            label: "Additional Living Exp.",
            readonly: true
          }),
          useTextField({
            key: "OTS",
            label: "Other Structures",
            readonly: true
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useTextField({
            key: "UPP",
            label: "Unscheduled Pers. Prop.",
            readonly: true
          }),
          useTextField({
            key: "MPO",
            label: "Medical Payments Only",
            readonly: true
          }),
          useTextField({
            key: "SPP",
            label: "Scheduled Pers. Prop.",
            readonly: true
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useTextField({
            key: "LossPToDt",
            label: "Loss Paid To Date:",
            readonly: true
          })
        ],
        { layout: "full" }
      ),
      {
        element: "html",
        classList: ["-mt-12", "mb-12"],
        tagName: "h1",
        innerText:
          "Total of Payment Breakdown may not always equal total Loss Paid to Date as only the most common coverages are listed in the breakdown"
      }
    ],
    { title: "Payment Breakdown by Coverage" }
  )
];
