
import Vue from "vue";
import {
  claimsSchemas,
  coverageBreakDownSchemas
} from "./claimsStatusFormBuilderSchema";
import { claimsMapActions, claimsMapState } from "@/store/modules/claims";
import { downloadLossFormDocument } from "@/helpers/downloadLossForm";
import { get } from "lodash";
import DataView from "@/components/DataView/DataView.vue";

export default Vue.extend({
  name: "ClaimStatusDetails",
  data() {
    return {
      coverageBreakDownSchemas,
      // claim: new Session().get("claimsLookup")?.data,
      claimNumber: 0,
      coverageBreakDownData: {},
      showViewBreakdown: false
    };
  },
  props: {
    claim: {
      type: Object
    }
  },
  components: {
    DataView
  },
  methods: {
    ...claimsMapActions(["printClaimsLookupData"]),
    viewBreakdown() {
      this.showViewBreakdown = true;
      this.coverageBreakDownData = {
        DWG: this.claim.DWG,
        UPP: this.claim.UPP,
        ALE: this.claim.ALE,
        MPO: this.claim.MPO,
        SPP: this.claim.SPP,
        OTS: this.claim.OTS
      };
      this.scrollToCoverages();
    },
    async doPrintClaimsLookupData() {
      await downloadLossFormDocument.bind(this)({ ...this.claim });
    },
    scrollToCoverages() {
      window.scrollBy(0, 1000);
    }
  },
  computed: {
    ...claimsMapState(["makingApiRequest"]),
    claimsDetailSchemas(): any {
      return claimsSchemas(this.claim);
    },
    getData() {
      let result = {};
      result = {
        attachments: [],
        sections: [
          {
            sectionTitle: `${get(
              this.claim,
              "CompanyName",
              ""
            ).toUpperCase()} POLICY INFORMATION`,
            showSection: true,
            sectionColumnSize: "3",
            information: [
              {
                informationColumnSize: "1",
                key: "Name",
                value: get(this.claim, "Name")
              },
              {
                informationColumnSize: "1",
                key: "Claim Number",
                value: get(this.claim, "ClaimNumber")
              },
              {
                informationColumnSize: "1",
                key: "Policy Number",
                value: get(this.claim, "PolicyNum")
              },
              {
                informationColumnSize: "1",
                key: "Address",
                value: get(this.claim, "Address")
              },
              {
                informationColumnSize: "1",
                key: "City",
                value: get(this.claim, "City")
              },
              {
                informationColumnSize: "1",
                key: "State",
                value: get(this.claim, "State")
              },
              {
                informationColumnSize: "1",
                key: "Zip Code",
                value: get(this.claim, "Zip")
              }
            ]
          },
          {
            sectionTitle: "CLAIM INFORMATION",
            showSection: true,
            sectionColumnSize: "3",
            showDivider: true,
            information: [
              {
                informationColumnSize: "1",
                key: "Claim Number",
                value: get(this.claim, "ClaimNumber")
              },
              {
                informationColumnSize: "1",
                key: "Date of Loss",
                value: get(this.claim, "LossDt")
              },
              {
                informationColumnSize: "1",
                key: "Cause Of Loss",
                value: get(this.claim, "CauseOfLoss")
              },
              {
                informationColumnSize: "1",
                key: "Claim Status",
                value: get(this.claim, "ClaimsStatus")
              },
              {
                informationColumnSize: "1",
                key: "Loss Paid To Date",
                value: get(this.claim, "LossPToDt")
              },
              {
                informationColumnSize: "1",
                key: "Staff Adjuster",
                value: get(this.claim, "StaffAdj")
              },
              {
                informationColumnSize: "1",
                key: "Extension",
                value: get(this.claim, "Extension")
              },
              {
                informationColumnSize: "1",
                key: "Field Adjuster",
                value: get(this.claim, "FieldAdj")
              },
              {
                informationColumnSize: "1",
                key: "Field Adjuster's Phone Number",
                value: get(this.claim, "PhoneNum")
              }
            ]
          },
          {
            sectionTitle: "Payment Breakdown by Coverage",
            showSection: this.showViewBreakdown,
            sectionColumnSize: "3",
            showDivider: true,
            information: [
              {
                informationColumnSize: "1",
                key: "Dwelling",
                value: get(this.claim, "DWG")
              },
              {
                informationColumnSize: "1",
                key: "Additional Living Exp.",
                value: get(this.claim, "ALE")
              },
              {
                informationColumnSize: "1",
                key: "Other Structures",
                value: get(this.claim, "OTS")
              },
              {
                informationColumnSize: "1",
                key: "Unscheduled Pers. Prop.",
                value: get(this.claim, "UPP")
              },
              {
                informationColumnSize: "1",
                key: "Medical Payments Only",
                value: get(this.claim, "MPO")
              },
              {
                informationColumnSize: "1",
                key: "Scheduled Pers. Prop.",
                value: get(this.claim, "SPP")
              },
              {
                informationColumnSize: "1",
                key: "Loss Paid To Date:",
                value: get(this.claim, "LossPToDt")
              }
            ]
          }
        ]
      };
      return result;
    }
  }
});
